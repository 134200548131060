import fedi from "../../../assets/img/2023-2024/cs/FediBrinsiCHAIR.jpg";
import firastalbi from "../../../assets/img/2023-2024/cs/FirasTalbiVICE.jpg";
import farah from "../../../assets/img/2023-2024/cs/FarahJedlySG.jpg";
import charfa from "../../../assets/img/2023-2024/cs/MohamedCharfeddineTREASURER.jpg";
import aminemaalej from "../../../assets/img/2023-2024/cs/AmineMaalejWEBMASTER.jpg";
import maryemdrira from "../../../assets/img/2023-2024/cs/MaryemDriraHR.jpg";

const officers = [
    {
        "name": "Fedi Brinsi",
        "position": "Chairman",
        "image": fedi,
        "chapter": "cs"
    },
    {
        "name": "Firas Talbi",
        "position": "Vice Chairman",
        "image": firastalbi,
        "chapter": "cs"
    },
    {
        "name": "Farah Jedly",
        "position": "Secretary",
        "image": farah,
        "chapter": "cs"
    },
    {
        "name": "Mohamed Charfeddine",
        "position": "Treasurer",
        "image": charfa,
        "chapter": "cs"
    },
    {
        "name": "Amine Maalej",
        "position": "Webmaster",
        "image": aminemaalej,
        "chapter": "cs"
    },
    {
        "name": "Maryem Drira",
        "position": "Human Resources",
        "image": maryemdrira,
        "chapter": "cs"
    },
]

export default officers