import ieeeday from "../../../assets/img/2023-2024/projects/sb/IEEEDAY.png";
import soft from "../../../assets/img/2023-2024/projects/sb/so3ft.jpg";
import team from "../../../assets/img/2023-2024/projects/sb/IntegrationDay-.jpg";
import maga from "../../../assets/img/2023-2024/projects/sb/maga.jpg";

const activities = [
    {
        title: "IEEEDAY",
        description: "IEEEDay is an annual event that celebrates the anniversary of the IEEE organization. It is celebrated by all IEEE student branches around the world. The event is usually held in October. The event is celebrated by organizing a variety of activities, including technical and non-technical activities.",
        image: ieeeday,
        link: "https://www.google.com",
        chapter: "sb"
    },
    {
        title: "SOFT SKILLS WORKSHOPS",
        description: "We always try to make sure that our members get as many soft skills as technical ones. For that, we organized a small series of soft skills workshops online (Design thinking/ideation - pitching - BMC, etc..) in order to prepare them for the competitions and more specifically the challenges of the TSYP Congress.",
        image: soft,
        link: "https://www.google.com",
        chapter: "sb"
    },
    {
        title: "TEAM BUILDING",
        description: "We believe that great work, innovation, and motivation come from the times where we enjoy ourselves, where we get closer together and have fun. That’s why we hold some integration activities upon the arrival of new members, as well as some other fun activities that we call “family time”. United we stand, divided we fall!",
        image: team,
        link: "https://www.google.com",
        chapter: "sb"
    },
    {
        title: "IEEE FST MAGAZINE- IEEE JOURNEY",
        description: "A creative and unique team of IEEE FST SB members worked on the very first edition of the IEEE FST Magazine, which contains facts about our student branch, some testimonials with our old and new members, interview with Oussema Fitouri, Chair of the IEEE SAC Committee and Chair of the IEEE CS Chapter Tunisia Section and last but not least some fun games. New exclusive editions of our magazine will be available very soon!",
        image: maga,
        link: "https://www.google.com",
        chapter: "sb"
    },
]

export default activities