import miri from "../../../assets/img/2023-2024/projects/grss/miri.png";
import jpo from "../../../assets/img/2023-2024/projects/grss/jpo.png";
import structuralgeomodeling
    from "../../../assets/img/2023-2024/projects/grss/structural-Geology-modelling-numerical-and-physical-approaches.jpg";
import movienight from "../../../assets/img/2023-2024/projects/grss/20201028_145628.jpg";
import timestressmgmnt from "../../../assets/img/2023-2024/projects/grss/Time-and-stress-management.jpg";
import ceo5 from "../../../assets/img/2023-2024/projects/grss/geo5.jpg";

const activities = [
    {
        title: "GRS 1.0",
        description: "Explore the forefront of technology and environmental sustainability at Green Remote Sensing 1.0 (GRS1.0). Organized by the IEEE Geoscience and Remote Sensing Society (GRSS) FST Student Branch, in collaboration with IEEE GRSS MSE and IEEE GRSS INAT, this symposium is scheduled to take place at the FST Faculty of Sciences of Tunis Manar on November 26, 2023, with a focus on \"Sensing Climate Change Solutions.\"",
        image: miri,
        link: "https://www.google.com",
        chapter: "grss"
    },
    {
        title: "JPO3E",
        description: "Since the beginning, our chapter has been active and launched its first event “JPO3E of the Department of Geology FSTunis” with the collaboration of 4C FST, Optima, Géomatique, and TAYA. Due to the pandemic situation, we had to launch the JPO3E online on the 18 of November and the 25 of November 2020. This event offers companies a space to present their projects and internship offers and gives you access to a conference led by experts to properly expose their companies, share their experiences and give advice.",
        image: jpo,
        link: "https://www.google.com",
        chapter: "grss"
    },
    {
        title: "Structural geology modeling",
        description: "Session 1 of Structural geology modeling: Numerical and physical approaches using the software MOOB. by Mannoubi Khelil. Due to the Covid-19 situation, we held this workshop online on Google Meet. November 15/2020: Session2 of Structural geology modeling: Balancing and restoration by Mannoubi Khelil.",
        image: structuralgeomodeling,
        link: "https://www.google.com",
        chapter: "grss"
    },
    {
        title: "movies nights",
        description: "Asides from the work and training we allow ourselves to have some fun in order to recharge our creativity and energy. For that, we had 2 movie nights on December 28/2020, and January 15/2021.",
        image: movienight,
        link: "https://www.google.com",
        chapter: "grss"
    },
    {
        title: "Time and Stress Management",
        description: "online by Hiba Arfaoui where we had the chance to find solutions to tackle our responsibilities in times of stress.",
        image: timestressmgmnt,
        link: "https://www.google.com",
        chapter: "grss"
    },
    {
        title: "2 workshops CEO5",
        description: "February 20/2021: CEO5 by Mohamed Agili. : terrain and slope stability. This training was held at the FST.\n" +
            "February 27/2021: CEO5 by Mohamed Agili: Design and verification of retaining screens( presentiel)",
        image: ceo5,
        link: "https://www.google.com",
        chapter: "grss"
    },
]

export default activities