import ghada from "../../../assets/img/2023-2024/grss/Ghada dhaouadi.jpg";
import mariem from "../../../assets/img/2023-2024/grss/Mariem zaghdoud .jpeg";
import kounouz from "../../../assets/img/2023-2024/grss/Kounouz Bouzaiene.jpg";
import raniataboubi from "../../../assets/img/2023-2024/grss/Rania taboubi.jpeg";
import hibabenhabib from "../../../assets/img/2023-2024/grss/Hiba Ben Lahbib.jpg";
import maryem from "../../../assets/img/2023-2024/grss/Maryem ben khelifa.jpeg";

const officers = [
    {
        "name": "Ghada Dhaouadi",
        "position": "Chairwoman",
        "image": ghada,
        "chapter": "grss"
    },
    {
        "name": "Mariem Zaghdoud",
        "position": "Vice Chairwoman",
        "image": mariem,
        "chapter": "grss"
    },
    {
        "name": "Kounouz Bouzaiene",
        "position": "Secretary",
        "image": kounouz,
        "chapter": "grss"
    },
    {
        "name": "Rania Taboubi",
        "position": "Treasurer",
        "image": raniataboubi,
        "chapter": "grss"
    },
    {
        "name": "Hiba Ben Habib",
        "position": "Webmaster",
        "image": hibabenhabib,
        "chapter": "grss"
    },
    {
        "name": "Mariem Ben Khelifa",
        "position": "Human Resources",
        "image": maryem,
        "chapter": "grss"
    },
]

export default officers