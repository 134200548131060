import houssem from "../../../assets/img/2023-2024/ras/houssemchair.jpeg";
import najeh from "../../../assets/img/2023-2024/ras/najehvp.jpeg";
import rana from "../../../assets/img/2023-2024/ras/ranasg.jpeg";
import ahmed from "../../../assets/img/2023-2024/ras/ahmedhedrichtreso.jpeg";
import bouba from "../../../assets/img/2023-2024/ras/boubamedia.jpeg";
import lina from "../../../assets/img/2023-2024/ras/linarh.jpeg";
import fraj from "../../../assets/img/2023-2024/ras/frajlogistics.jpeg";

const officers = [
    {
        "name": "Houssem Dakhli",
        "position": "Chairman",
        "image": houssem,
        "chapter": "ras"
    },
    {
        "name": "Najeh Ghanmi",
        "position": "Vice Chairman",
        "image": najeh,
        "chapter": "ras"
    },
    {
        "name": "Rana Hajri",
        "position": "Secretary",
        "image": rana,
        "chapter": "ras"
    },
    {
        "name": "Ahmed Hedrich",
        "position": "Treasurer",
        "image": ahmed,
        "chapter": "ras"
    },
    {
        "name": "Melek Boubahri",
        "position": "Webmaster",
        "image": bouba,
        "chapter": "ras"
    },
    {
        "name": "Lina Yahyaoui",
        "position": "Human Resources Manager",
        "image": lina,
        "chapter": "ras"
    },
    {
        "name": "Fraj Ben Abdelaziz",
        "position": "Logistics Manager",
        "image": fraj,
        "chapter": "ras"
    },
]

export default officers