import agcs from "../../../assets/img/2023-2024/projects/cs/General-Assembly.png";
import psw from "../../../assets/img/2023-2024/projects/cs/Problem-Solving-Workshop.png";
import xtreme from "../../../assets/img/2023-2024/projects/cs/xtreme.png";
import sponso from "../../../assets/img/2023-2024/projects/cs/Sponsoring-Workshop-1.jpg";
import designthinking from "../../../assets/img/2023-2024/projects/cs/Design-Thinking-Workshop.jpg";
import webdev from "../../../assets/img/2023-2024/projects/cs/Webdev.jpg";
import codakids from "../../../assets/img/2023-2024/projects/cs/CODAKIDS.png";
import fsthack from "../../../assets/img/2023-2024/projects/cs/FST-HACKATHON-1.jpg";
import talks from "../../../assets/img/2023-2024/projects/cs/Activities.jpg";

const activities = [
    {
        title: "GENERAL ASSEMBLY",
        description: "We always have such pleasure to host our dear members in the beggining of the mandate to reveal our strategy and discuss all the details together in order to keep them updated with our plans as well as ensuring great first impression from the CS chapter and elevating their expectations for its activities.",
        image: agcs,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "Problem Solving Workshops",
        description: "Problem-solving series workshop have been held on October, 5th, 8th, 12th and 15th. It’s a series sessions, in order to get ready for the IEEEXTREME competition, that helps our members to understand the root cause of a problem,\n" +
            "quickly generate ideas to solve it and evaluate them to ensure they’re robust, then make a plan to test or implement the solution.",
        image: psw,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "IEEEXTREME",
        description: "IEEEXtreme is an internal competition in which teams of all IEEE Student members – advised and proctored by an IEEE young professional member, and often supported by an IEEEStudent Branch, compete in a 24-hour time span against each other to solve a set of programming problems.",
        image: xtreme,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "SPONSORING WORKSHOP",
        description: "On November 19th, we had a sponsoring workshop with our dear instructor Oussema Ben Said. Arranging sponsorship is a long process that requires a particular skills such as negotiation skills and so on. Sponsoring have a great impact on the success of an organized event. For that matter, we always manage to have this workshop in order to famillarize our dear members with this concept.",
        image: sponso,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "DESIGN THINKING WORKSHOP",
        description: "Design thinking process has found its usefulness in breaking down complex problems into tangible, economically viable solutions. Acquiring such a skill would be beneficial, which leads us to host a Design thinking Workshop on Wednesday,November 23th presented by our dear professional trainer Anas Tradi.",
        image: designthinking,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "WEB DEVELOPMENT WORKSHOP",
        description: "During our Web Development series wrokshop, our members explore the building blocks of the web — HTML and CSS — and learn how they can apply these programming languages to develop their own simple sites.",
        image: webdev,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "CODAKIDS",
        description: "Codakids is a one-hour introduction to coding and computer science presented by IEEE FST CS members, that will help kids to learn the basics in a fun and simple way,\n" +
            "develop vital problem-solving and critical-thinking skills and to become creators and not simply consumers of technology.",
        image: codakids,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "FST HACKATHON",
        description: "FST Hackathon is a software engineering competition co-hosted by our Chapter. The event aims to encourage young students and developers to challenge themselves and use their skills to face real world challenges as they solve different sets of problems. It consists of three main challenges; Cyber Security, Web Development and Artificial Intelligence.",
        image: fsthack,
        link: "https://www.google.com",
        chapter: "cs"
    },
    {
        title: "IEEE TALKS",
        description: "It’s an online gathering for fun for IEEE FST Members where they can discuss about an IT subject and share their ideas and knowledge. It’s up to them to choose the subject of interest and then there will be volunteers that would prepare the content of the session in order to let everyone familiar with this technology.",
        image: talks,
        link: "https://www.google.com",
        chapter: "cs"
    },
]

export default activities