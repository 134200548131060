import rania from "../../../assets/img/2023-2024/sb/rania.jpg";
import ghassen from "../../../assets/img/2023-2024/sb/ghassen.jpg";
import hamza from "../../../assets/img/2023-2024/sb/hamza.jpg";
import arijcoord from "../../../assets/img/2023-2024/sb/arijcoord.jpg";
import arijrh from "../../../assets/img/2023-2024/sb/arijrh.jpg";

const officers = [
    {
        "name": "Rania Bouazizi",
        "position": "Chairwoman",
        "image": rania,
        "chapter": "sb"
    },
    {
        "name": "Ghassen Bougacha",
        "position": "Treasurer",
        "image": ghassen,
        "chapter": "sb"
    },
    {
        "name": "Hamza Mami",
        "position": "Webmaster",
        "image": hamza,
        "chapter": "sb"
    },
    {
        "name": "Arij Jdidi",
        "position": "Coordinator",
        "image": arijcoord,
        "chapter": "sb"
    },
    {
        "name": "Arij Ben Hadj Ismail",
        "position": "Human Resources Manager",
        "image": arijrh,
        "chapter": "sb"
    },
]

export default officers