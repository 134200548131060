import React from "react";
import {Helmet} from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import CS from "./screens/CsChapter";
import WIE from "./screens/WIEAffinity";
import IAS from "./screens/IASChapter";
import RAS from "./screens/RASChapter";
import GRSS from "./screens/GRSSChapter";
import PES from "./screens/PESChapter";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Error404 from "./screens/Error404";

const App = () => {
    return (
        <>
            <BrowserRouter basename={"/"}>
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                    <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
                          rel="stylesheet"/>
                </Helmet>
                <Routes>
                    <Route path={"/"} element={<Landing/>}/>
                    <Route path={"/cs-chapter"} element={<CS/>}/>
                    <Route path={"/ias-chapter"} element={<IAS/>}/>
                    <Route path={"/ras-chapter"} element={<RAS/>}/>
                    <Route path={"/pes-chapter"} element={<PES/>}/>
                    <Route path={"/grss-chapter"} element={<GRSS/>}/>
                    <Route path={"/wie-affinity-group"} element={<WIE/>}/>
                    <Route path={"/errors/404"} element={<Error404/>}/>
                    <Route path="*" element={<Navigate to="/errors/404" replace />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;