import hiba from "../../../assets/img/2023-2024/ias/hiba.png";
import yosri from "../../../assets/img/2023-2024/ias/yosri.jpeg";
import aya from "../../../assets/img/2023-2024/ias/aya.png";
import fatma from "../../../assets/img/2023-2024/ias/fatma.jpg";

const officers = [
    {
        "name": "Hiba Hedfi",
        "position": "Chairwoman",
        "image": hiba,
        "chapter": "ias"
    },
    {
        "name": "Yosri Essaleh",
        "position": "Vice Chairman",
        "image": yosri,
        "chapter": "ias"
    },
    {
        "name": "Eya Khiari",
        "position": "Treasurer",
        "image": aya,
        "chapter": "ias"
    },
    {
        "name": "Fatma Andolsi",
        "position": "Webmaster",
        "image": fatma,
        "chapter": "ias"
    },
]

export default officers