import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Team from "../components/Sections/Team";
import Blog from "../components/Sections/Blog";

export default function Landing() {
  return (
    <>
      <TopNavbar chapter={"grss"} />
      <Header chapter={"grss"} />
      <Services chapter={"grss"} />
      <Projects chapter={"grss"} />
      <Blog chapter={"grss"} />
      <Team chapter={"grss"} />
      <Contact chapter={"grss"} />
      <Footer chapter={"grss"} />
    </>
  );
}


