import competition from "../../../assets/img/2023-2024/projects/pes/pes-international-competition.png";
import workshop1 from "../../../assets/img/2023-2024/projects/pes/Workshop-1-Introduction-to-energypower-and-renewable-energy1.png";
import webinar from "../../../assets/img/2023-2024/projects/pes/PES-WEBINAR-WOMAN-IN-POWER1.png";
import movienight from "../../../assets/img/2023-2024/projects/pes/1-1.jpg";

const activities = [
    {
        title: "International competition",
        description: "We were beyond thrilled to announce our first international competition on January 2022 , about climate change when PES members divided into teams to compete with each other , by presenting their solution on climate change . So the pitching day took place on January 29th , when two teams presented their projects ; after the challenge the squad team gained the winners title .",
        image: competition,
        link: "https://www.google.com",
        chapter: "pes"
    },
    {
        title: "Integration Day",
        description: "IEEE PES FST Chapter Gathering where we had fun, did a lot of activities, laied some news concerning the upcoming events, challenges and most importantly had known each other. The gathering had been held on February 6th 2022 at 10 AM in BELVEDERE PARC .",
        image: workshop1,
        link: "https://www.google.com",
        chapter: "pes"
    },
    {
        title: "PES WEBINAR \" WOMEN IN POWER\"",
        description: "We held a webinar about ” WOMEN IN POWER” in collaboration with IEEE WIE FST affinity group and with our amazing stunning guests ” Olfa Khemiri” and “Balkis Chaabene” via google meet at 7 PM – 9 PM on wednesday 17th march.",
        image: webinar,
        link: "https://www.google.com",
        chapter: "pes"
    },
    {
        title: "PES MOVIE NIGHT",
        description: "To help the members enjoy their saturday night and integrate them more, we decided to plan a Movies night where we watched the documentary ”An Inconvenient Sequel: Truth to Power”.",
        image: movienight,
        link: "https://www.google.com",
        chapter: "pes"
    },
]

export default activities