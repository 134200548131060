import scrum from "../../../assets/img/2023-2024/projects/ias/scrum agile methodology workshop.jpg";
import projectbuilding from "../../../assets/img/2023-2024/projects/ias/project building.png";
import projectmgmnt from "../../../assets/img/2023-2024/projects/ias/project management.png";
import iasannualmeeting from "../../../assets/img/2023-2024/projects/ias/annual meeting.png";
import photoshopworkshop from "../../../assets/img/2023-2024/projects/ias/photoshop workshop.png";
import gizhack from "../../../assets/img/2023-2024/projects/ias/giz hack.png";

const activities = [
    {
        title: "Scrum agile methodology workshop",
        description: "Elevating our tech prowess one Raspberry Pi at a time. 🍓💻This weekend's Raspberry pi career building workshop series , proudly in partnership with Maker Skills ,was a  shared journey of learning that left us inspired and united.  Here's a glimpse into the dynamic world of our Raspberry Pi journey.",
        image: scrum,
        link: "https://www.google.com",
        chapter: "ias"
    },
    {
        title: "Project Building",
        description: "Project building workshop was held on october, 26th at the faculty of sciences of tunis by the board members : meysoun dahmeni, roua ayed and yosri salah. the workshop was dedicated as a first session to prepare the new members to make their ownproject ideas going through allthe necessairy steps presented in the workshop.",
        image: projectbuilding,
        link: "https://www.google.com",
        chapter: "ias"
    },
    {
        title: "Project Management",
        description: "Project management workshop was held on october, 28th by amal golli.this workshop aims to learn howto start, plan and execute a project using management approaches.",
        image: projectmgmnt,
        link: "https://www.google.com",
        chapter: "ias"
    },
    {
        title: "Ias annual meeting",
        description: "Ias annual meeting participation, on the 12th to the 13th of november, was a unique experience in which our IEEE IAS FST SBC have been participated in the non -technical challenge and have been nominated among the three finalists in that challenge.. Many ias members have been part of that experience first to build to idea of the project to pitch and then some of them got the chance topitch in front of juries for the first time in their career.",
        image: iasannualmeeting,
        link: "https://www.google.com",
        chapter: "ias"
    },
    {
        title: "Photoshop workshop",
        description: "The first photoshop session took place on november the 16th in the faculty of sciences in tunis, presented by mokhless ezzedini. The session was open for all ias members and aims to build the media team that will continue to work on the media content during this mandate.",
        image: photoshopworkshop,
        link: "https://www.google.com",
        chapter: "ias"
    },
    {
        title: "GIZ HACK",
        description: "GIZ HACK is a 24h national hackathon that was held on 3rd to the 4th of november in the faculty of sciences of tunis and stands for green industry zone, in collaboration between ias and pes chapters and have hosted 100 participant under the theme of smart city. During the competition the participants were challenged to solve real-life of juries. this event has the potential to drive economic and environmental growth and improves the quality of life of people for a better future of tunisia.",
        image: gizhack,
        link: "https://www.google.com",
        chapter: "ias"
    },
]

export default activities