import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import UsersIcon from "../../assets/svg/Services/UsersIcon";
import UserTieIcon from "../../assets/svg/Services/UserTieIcon";
import CalendarDaysIcon from "../../assets/svg/Services/CalendarDaysIcon";
import AwardIcon from "../../assets/svg/Services/AwardIcon";

export default function ServiceBox({icon, title, subtitle, image, altText, action}) {
    let ImgStyle = {
        width: "100%",
        maxWidth: "150px",
        margin: "0 auto",
        padding: "40px 0",
        borderRadius: "50%",
        "@media (max-width: 860px)": {
            padding: "20px 0",
        },
    }
    let getIcon;

    switch (icon) {
        case "roller":
            getIcon = <RollerIcon/>;
            break;
        case "monitor":
            getIcon = <MonitorIcon/>;
            break;
        case "browser":
            getIcon = <BrowserIcon/>;
            break;
        case "printer":
            getIcon = <PrinterIcon/>;
            break;
        case "users":
            getIcon = <UsersIcon/>;
            break;
        case "user-tie":
            getIcon = <UserTieIcon/>;
            break;
        case "calendar-days":
            getIcon = <CalendarDaysIcon/>;
            break;
        case "award":
            getIcon = <AwardIcon/>;
            break;
        default:
            getIcon = <RollerIcon/>;
            break;
    }


    return (
        <Wrapper className="flex flexColumn">
            {icon ? <IconStyle>{getIcon}</IconStyle> : image ?
                <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
                    <img style={ImgStyle} src={image} alt={altText}></img>
                </ImgBtn> : null}

            <TitleStyle className="font20 extraBold">{title}</TitleStyle>
            <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;
const IconStyle = styled.div`
    align-self: center;
    @media (max-width: 860px) {
        margin: 0 auto;
    }
`;
const TitleStyle = styled.h2`
    text-align: center;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    padding: 40px 0;
    @media (max-width: 860px) {
        padding: 20px 0;
    }
`;
const SubtitleStyle = styled.p`
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;