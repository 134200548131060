import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import posts from "../../data/2023-2024/posts";
import {useNavigate} from "react-router-dom";

export default function Blog({chapter}) {
  const navigate = useNavigate();
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold textCenter">Our Blog Stories</h1>
            <p className="font13 textCenter">
              Stories about our company, latest updates, events, news, <br />
                and what we are doing in general.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {posts.filter(post => post.chapter === chapter).slice(0, 3).map((post, index) => {
                return (
                    <div key={index} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <BlogBox
                        title={post.title}
                        text={post.content}
                        tag={post.category}
                        author={post.date}
                        action={() => alert("clicked")}
                    />
                    </div>
                )
            })}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => navigate('/blog')} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold textCenter">What They Say?</h1>
            <p className="font13 textCenter">
              Say something about our student branch, our team, <br />
                and what we do in general.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;