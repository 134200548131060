import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/2023-2024/projects/sb/DSC_0049.jpg";
import ContactImg2 from "../../assets/img/2023-2024/projects/sb/InternalComp-2.jpg";

export default function Contact() {

  // send an email to us with the form data react hook
    // const [serverState, setServerState] = useState({
    //   submitting: false,
    //   status: null
    // });
    // const handleServerResponse = (ok, msg, form) => {
    //   setServerState({
    //     submitting: false,
    //     status: { ok, msg }
    //   });
    //   if (ok) {
    //     form.reset();
    //   }
    // };
    // const handleOnSubmit = e => {
    //   e.preventDefault();
    //   const form = e.target;
    //   setServerState({ submitting: true });
    //   axios({
    //     method: "post",
    //     url: "https://formspree.io/mbjzjyqo",
    //     data: new FormData(form)
    //   })
    //     .then(r => {
    //       handleServerResponse(true, "Thanks!", form);
    //     })
    //     .catch(r => {
    //       handleServerResponse(false, r.response.data.error, form);
    //     });


  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Contact us for more information about our services and how we can help you. We are always happy to answer any questions you may have.
              Join us and let's build something great together.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">First name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" />
                <textarea rows="4" cols="50" id="message" name="message" className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} style={{width: 330, height: 278}} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} style={{width: 330, height: 330}} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









