import fstrobots3 from "../../../assets/img/2023-2024/projects/ras/fst robots3.jpg";
import arduino1 from "../../../assets/img/2023-2024/projects/ras/Arduino-1.jpg";
import arduino2 from "../../../assets/img/2023-2024/projects/ras/Workshop-arduino-2.jpg";
import enis50 from "../../../assets/img/2023-2024/projects/ras/ENIS5.0-participation.jpg";
import ag from "../../../assets/img/2023-2024/projects/ras/GA.png";
import integrationday from "../../../assets/img/2023-2024/projects/ras/Integration-day.png";

const activities = [
    {
        title: "FST Robots 3.0",
        description: "25 December | 8 PM | Online To start the holidays, we tackled our Level 1 Printed Circuit Board ( PCB ) design workshop with Farés Aissa’s, and Mahmoud Abidi’s guidance.",
        image: fstrobots3,
        link: "https://www.google.com",
        chapter: "ras"
    },
    {
        title: "First Arduino session",
        description: "It was animated by Raed Banneni on Wednesday the 26th of October at 14:30 in the preparatory department (SE4A). It contains Arduino and robotic knowledge necessities. Practice activities took place to make members familiar with Arduino.",
        image: arduino1,
        link: "https://www.google.com",
        chapter: "ras"
    },
    {
        title: "Second Arduino training session",
        description: "The Second Arduino workshop was held on 05 November 2022 at 01 pm at the FST at the Preparatory department, animated by our talented trainor Raed Banneni. First, Practical exercises were presented to the members to ensure a better understanding of Arduino programming. At the end of the workshop, members were able to activate the rotation of a simple motor in both senses with Arduino. These training sessions allowed members to gain knowledge and practical skills related to Arduino programming.",
        image: arduino2,
        link: "https://www.google.com",
        chapter: "ras"
    },
    {
        title: "RoboCup: ENIS robotic competition",
        description: "It took place on 27 November 2022 in the ENI Sfax. As RAS FST we’ve participated with three teams with a line follower robot & one team with an all terrain robot. It allowed new members to discover the robotic competition’s experience. All members showed a lot of motivation and energy during the competition.",
        image: enis50,
        link: "https://www.google.com",
        chapter: "ras"
    },
    {
        title: "General Assembly",
        description: "Our first assembly was held on Wednesday 5 October 2022 in the FST. Past executive board members were honored for their work during the past mandate. We’ve discussed our vision and objectives with our members. At the end of the general assembly, we got to know our members more by playing an interactive game.",
        image: ag,
        link: "https://www.google.com",
        chapter: "ras"
    },
    {
        title: "Integration Day",
        description: "We organised an integration day for our new members on Sunday the 16th of October at the park of Sidi Bou Said where we had the opportunity to break the ice and get closer to each other as one big family.",
        image: integrationday,
        link: "https://www.google.com",
        chapter: "ras"
    },
]

export default activities