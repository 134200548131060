const posts = [
    {
        // title: "Post 1",
        // date: "2023-2024",
        // content: "This is the first post",
        // slug: "post-1",
        // category: "general",
        // chapter: "sb"
    }
]

export default posts;