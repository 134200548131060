import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Team from "../components/Sections/Team";
import Blog from "../components/Sections/Blog";

export default function Landing() {
  return (
    <>
      <TopNavbar chapter={"cs"} />
      <Header chapter={"cs"} />
      <Services chapter={"cs"} />
      <Projects chapter={"cs"} />
      <Blog chapter={"cs"} />
      <Team chapter={"cs"} />
      <Contact chapter={"cs"} />
      <Footer chapter={"cs"} />
    </>
  );
}


