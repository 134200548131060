import React from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import officers from "../../data/2023-2024/officers";

const Team = ({chapter}) => {
    return (
        <Wrapper id="team">
            <div className="whiteBg">
                <div className="container">
                    <HeaderInfo>
                        <h1 className="font40 extraBold textCenter">Meet Our Team</h1>
                        <p className="font13 textCenter">
                            Our team is made up of professionals with extensive experience in the
                            field of IT.
                        </p>
                    </HeaderInfo>
                    <div className="row textCenter">
                        {officers.filter(officer => officer.chapter === chapter).map((officer) => (
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <ProjectBox
                                    img={officer.image}
                                    title={officer.name}
                                    text={officer.position}
                                />
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: #fff;
    padding: 80px 0;
    @media (max-width: 767px) {
        padding: 60px 0;
    }
`;

const HeaderInfo = styled.div`
    margin-bottom: 50px;
    @media (max-width: 860px) {
        text-align: center;
    }
`;

export default Team;