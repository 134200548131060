import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Being part of the IEEE FST Student Branch has been an extraordinary experience. The vibrant community, cutting-edge events, and the collaborative spirit have enriched my academic journey. IEEE FST SB is not just a student organization; it's a family that fosters innovation and personal growth. Proud to lead such an inspiring group of individuals!"
            author="Arij Saied, Chairwoman 2023-2024"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="IEEE FST SB has been a pivotal part of my university life. As the Vice Chairwoman, I've had the privilege of contributing to impactful projects, attending insightful workshops, and building lasting friendships. The sense of belonging and the opportunities for skill development make IEEE FST SB more than just a club – it's a dynamic hub for aspiring engineers."
            author="Rania Bouazizi, Vice Chairwoman 2023-2024"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Joining IEEE FST SB was a game-changer for me. As the Secretary, I've been involved in organizing engaging events and workshops that bridge the gap between academics and industry. The supportive atmosphere and collaborative projects have not only enhanced my technical skills but also provided a platform to make a real impact. Proud to be part of the IEEE family!"
            author="Sawsen Zerzeri, Secretary 2023-2024"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="IEEE FST SB has been my go-to place for exploring my passion for programming. The Programming Committee's hands-on coding sessions and hackathons have not only sharpened my technical skills but also allowed me to work on real-world projects. The sense of camaraderie and the emphasis on learning make it an exceptional community."
            author="Mohamed Ali, Programming Committee Member 2023-2024"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Serving as the Outreach Coordinator at IEEE FST SB has been an incredible journey. From organizing STEM outreach programs in local schools to collaborating with industry professionals, I've had the chance to make a positive impact on our community. IEEE FST SB is not just about personal growth but also about giving back and inspiring the next generation."
            author="Amira Haddad, Outreach Coordinator 2023-2024"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="IEEE FST SB has been a haven for my love of robotics. The Robotics Club's challenging projects and competitions have pushed me to think innovatively and apply theoretical knowledge in a practical setting. The sense of accomplishment when seeing our creations come to life is unmatched. IEEE FST SB truly nurtures passion and curiosity in technology."
            author="Ahmed Mansour, Robotics Enthusiast 2023-2024"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
