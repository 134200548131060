import sbofficers from "./sb/officers"
import csofficers from "./cs/officers"
import wieofficers from "./wie/officers"
import iasofficers from "./ias/officers"
import grssofficers from "./grss/officers"
import pesofficers from "./pes/officers"
import rasofficers from "./ras/officers"

const officers = [
    ...sbofficers,
    ...csofficers,
    ...wieofficers,
    ...iasofficers,
    ...grssofficers,
    ...pesofficers,
    ...rasofficers
]

export default officers;