import wael from "../../../assets/img/2023-2024/pes/ajem wael.jpg";
import sarra from "../../../assets/img/2023-2024/pes/melliti sarra.jpg";
import emna from "../../../assets/img/2023-2024/pes/bouziri emna.jpg";
import mohamed from "../../../assets/img/2023-2024/pes/moulahi mohamed.jpg";

const officers = [
    {
        "name": "Wael Ajem",
        "position": "Chairman",
        "image": wael,
        "chapter": "pes"
    },
    {
        "name": "Emna Bouziri",
        "position": "Secretary",
        "image": emna,
        "chapter": "pes"
    },
    {
        "name": "Mohamed Moulahi",
        "position": "Webmaster",
        "image": mohamed,
        "chapter": "pes"
    },
    {
        "name": "Sarra Melliti",
        "position": "Human Resources",
        "image": sarra,
        "chapter": "pes"
    },
]

export default officers