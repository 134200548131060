import agwie from "../../../assets/img/2023-2024/projects/wie/AG.jpg";
import stand from "../../../assets/img/2023-2024/projects/wie/Stand.jpg";
import teamwork from "../../../assets/img/2023-2024/projects/wie/Team-Work.jpg";
import occonference from "../../../assets/img/2023-2024/projects/wie/OC-for-the-conference-of-the-association.jpg";
import timemgmnt from "../../../assets/img/2023-2024/projects/wie/Time-Management-Workshop.jpg";
import movietime from "../../../assets/img/2023-2024/projects/wie/Movie-Time.jpg";
import kidsrobotics from "../../../assets/img/2023-2024/projects/wie/Workshop-kids.png";
import sponsoworkshop from "../../../assets/img/2023-2024/projects/wie/Sponso.jpg";

const activities = [
    {
        title: "General Assembly",
        description: "held on October 12th. It was our first official meeting with all WIE members we got to know each other and present our vision for the whole mandate.",
        image: agwie,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "Donation Stand",
        description: "Our breast cancer awareness stand at the Faculty of Sciences Tunis in collaboration with the association ATAMCS and the medical center “Wahida Bishikh”. We got to collect donations and spread awareness about the disease to all students of the Faculty.",
        image: stand,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "WORKSHOP TEAMWORK",
        description: "We started the mandate with this workshop held on October 19th with our speaker Montassar ghazouani. He showed us the importance of this skill and how to work efficiently with a team despite all differences.",
        image: teamwork,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "OC FOR THE CONFERENCE OF THE ASSOCIATION",
        description: "On October 23rd there was the association’s pink October open day where we were the OC team for it we helped set up the place and be present for the great cause.",
        image: occonference,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "WORKSHOP TIME MANAGEMENT",
        description: "held on November 11th with yet again our speaker Montassar Ghazouani which was the second and last part of the series of sessions Montassar prepared for us dedicated to the members for better understating of the workflow. He showed us the importance of scheduling and managing our time between our school work and the associative life.",
        image: timemgmnt,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "MOVIE TIME",
        description: "held on November 23rd Movie Time was a collaboration with CinéClub FST we watched the Screening of “PETITE MAMAN” which was related to our theme of the month of November which was Kids it was also a way to better integrate the members and have some fun outside of work and the stress of school.",
        image: movietime,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "ROBOTICS WORKSHOP FOR KIDS",
        description: "held on November 24th. It was the first workshop of our series of sessions dedicated to the kids of Horizon private school. This workshop was an introduction to the robotics world where we wanted to tickle their curiosity in this domain and give them a glimpse of this field if they want to pursue it in the future.",
        image: kidsrobotics,
        link: "https://www.google.com",
        chapter: "wie"
    },
    {
        title: "WORKSHOP SPONSORING",
        description: "held on November 27th online with our speaker Ghassen Ghannem. we learned all the sponsorship tricks such as the sponsoring file and how to negotiate with the sponsor through activities.",
        image: sponsoworkshop,
        link: "https://www.google.com",
        chapter: "wie"
    },
]

export default activities