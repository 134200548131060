import meha from "../../../assets/img/2023-2024/wie/Meha.jpeg";
import baraa from "../../../assets/img/2023-2024/wie/Baraa1.jpeg";
import yosr from "../../../assets/img/2023-2024/wie/Yosr1.jpeg";
import shaima from "../../../assets/img/2023-2024/wie/Shaima1.jpeg";
import hadyl from "../../../assets/img/2023-2024/wie/Hadyl.jpeg";

const officers = [
    {
        "name": "Meha Najar",
        "position": "Chairwoman",
        "image": meha,
        "chapter": "wie"
    },
    {
        "name": "Baraa Amiri",
        "position": "Secretary",
        "image": baraa,
        "chapter": "wie"
    },
    {
        "name": "Yosr Ben Mahmoud",
        "position": "Treasurer",
        "image": yosr,
        "chapter": "wie"
    },
    {
        "name": "Shaima Maafi",
        "position": "Webmaster",
        "image": shaima,
        "chapter": "wie"
    },
    {
        "name": "Hadil Hamza",
        "position": "Human Resources",
        "image": hadyl,
        "chapter": "wie"
    },
]

export default officers